import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
    
});

export const lightThemes = {
    colors: {
        "primary": "rgb(185, 31, 0)",
        "onPrimary": "rgb(255, 255, 255)",
        "primaryContainer": "rgb(255, 218, 211)",
        "onPrimaryContainer": "rgb(62, 5, 0)",
        "secondary": "rgb(119, 87, 80)",
        "onSecondary": "rgb(255, 255, 255)",
        "secondaryContainer": "rgb(255, 218, 211)",
        "onSecondaryContainer": "rgb(44, 21, 16)",
        "tertiary": "rgb(110, 92, 46)",
        "onTertiary": "rgb(255, 255, 255)",
        "tertiaryContainer": "rgb(249, 224, 166)",
        "onTertiaryContainer": "rgb(36, 26, 0)",
        "error": "rgb(186, 26, 26)",
        "onError": "rgb(255, 255, 255)",
        "errorContainer": "rgb(255, 218, 214)",
        "onErrorContainer": "rgb(65, 0, 2)",
        "background": "rgb(255, 251, 255)",
        "onBackground": "rgb(32, 26, 25)",
        "surface": "rgb(255, 251, 255)",
        "onSurface": "rgb(32, 26, 25)",
        "surfaceVariant": "rgb(245, 221, 217)",
        "onSurfaceVariant": "rgb(83, 67, 64)",
        "outline": "rgb(133, 115, 111)",
        "outlineVariant": "rgb(216, 194, 189)",
        "shadow": "rgb(0, 0, 0)",
        "scrim": "rgb(0, 0, 0)",
        "inverseSurface": "rgb(54, 47, 45)",
        "inverseOnSurface": "rgb(251, 238, 235)",
        "inversePrimary": "rgb(255, 180, 165)",
        "elevation": {
          "level0": "transparent",
          "level1": "rgb(252, 240, 242)",
          "level2": "rgb(249, 233, 235)",
          "level3": "rgb(247, 227, 227)",
          "level4": "rgb(247, 225, 224)",
          "level5": "rgb(245, 220, 219)"
        },
        "surfaceDisabled": "rgba(32, 26, 25, 0.12)",
        "onSurfaceDisabled": "rgba(32, 26, 25, 0.38)",
        "backdrop": "rgba(59, 45, 42, 0.4)"
    }
};

export const darkThemes = {
    colors: {
        "primary": "rgb(255, 180, 165)",
        "onPrimary": "rgb(100, 12, 0)",
        "primaryContainer": "rgb(141, 21, 0)",
        "onPrimaryContainer": "rgb(255, 218, 211)",
        "secondary": "rgb(231, 189, 180)",
        "onSecondary": "rgb(68, 42, 36)",
        "secondaryContainer": "rgb(93, 63, 57)",
        "onSecondaryContainer": "rgb(255, 218, 211)",
        "tertiary": "rgb(220, 196, 140)",
        "onTertiary": "rgb(61, 47, 4)",
        "tertiaryContainer": "rgb(85, 69, 25)",
        "onTertiaryContainer": "rgb(249, 224, 166)",
        "error": "rgb(255, 180, 171)",
        "onError": "rgb(105, 0, 5)",
        "errorContainer": "rgb(147, 0, 10)",
        "onErrorContainer": "rgb(255, 180, 171)",
        "background": "rgb(32, 26, 25)",
        "onBackground": "rgb(237, 224, 221)",
        "surface": "rgb(32, 26, 25)",
        "onSurface": "rgb(237, 224, 221)",
        "surfaceVariant": "rgb(83, 67, 64)",
        "onSurfaceVariant": "rgb(216, 194, 189)",
        "outline": "rgb(160, 140, 136)",
        "outlineVariant": "rgb(83, 67, 64)",
        "shadow": "rgb(0, 0, 0)",
        "scrim": "rgb(0, 0, 0)",
        "inverseSurface": "rgb(237, 224, 221)",
        "inverseOnSurface": "rgb(54, 47, 45)",
        "inversePrimary": "rgb(185, 31, 0)",
        "elevation": {
          "level0": "transparent",
          "level1": "rgb(43, 34, 32)",
          "level2": "rgb(50, 38, 36)",
          "level3": "rgb(57, 43, 40)",
          "level4": "rgb(59, 45, 42)",
          "level5": "rgb(63, 48, 45)"
        },
        "surfaceDisabled": "rgba(237, 224, 221, 0.12)",
        "onSurfaceDisabled": "rgba(237, 224, 221, 0.38)",
        "backdrop": "rgba(59, 45, 42, 0.4)"
      }
}