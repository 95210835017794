import { View, Dimensions, useWindowDimensions } from 'react-native'
import React from 'react'
import { Avatar, Button, Card, Text } from 'react-native-paper'
import { PreferencesContext } from '../context/PreferencesContext';
import { darkThemes, lightThemes } from '../theme/themeColors';
import { useLinkTo } from '@react-navigation/native';

const screenWitdh = Dimensions.get('window').width;
const screenHeight = Dimensions.get('window').height;

const IsComingSoonScreen = () => {

    const { isThemeDark } = React.useContext(PreferencesContext);
    const theme = isThemeDark ? darkThemes : lightThemes;

    const width = useWindowDimensions().width;

    const linkTo = useLinkTo();

    return (

        <View style={{ justifyContent: 'center', alignItems: 'center', flex: 1, backgroundColor: theme.colors.background }}>
            <Card
                theme={theme}
                style={{ width: screenWitdh * 0.95, height: 'auto', alignSelf: 'center', marginTop: 20, marginBottom: 20 }}
            >
                <Card.Cover source={{ uri: width > 500 ? 'https://firebasestorage.googleapis.com/v0/b/a-manyar-web.appspot.com/o/banner-web-a-manyar.png?alt=media&token=1bb87998-7906-4d90-964f-ed1f3dbf10f2' : 'https://firebasestorage.googleapis.com/v0/b/a-manyar-web.appspot.com/o/banner-movil-a-manyar.png?alt=media&token=5b634b96-4a60-45dd-a734-24ee91859a3d' }} style={{ height: screenHeight * 0.85 }} alt='Banner de la web de A Manyar' resizeMethod='resize' resizeMode='cover' />
                <Card.Title
                    title="Estamos construyendo este sitio"
                    titleVariant='headlineMedium'
                    titleStyle={{ marginLeft: 10 }}
                    subtitle="Muy pronto estará disponible"
                    style={{ marginTop: 20 }}
                    subtitleVariant='titleMedium'
                    subtitleStyle={{ marginLeft: 10 }}
                    left={(props) => <Avatar.Icon {...props} icon="tools" style={{ backgroundColor: theme.colors.inversePrimary, marginRight: 20 }} size={50} />}
                />
                <View style={{ marginTop: 10, flexDirection: width > 500 ? 'row' : 'column', marginBottom: 20, justifyContent: width > 500 ? 'flex-end' : 'center' , marginRight: width > 500 ? 20 : 0, alignItems: width > 500 ? 'flex-end': 'center' }}>
                    <Button
                        theme={theme}
                        onPress={() => linkTo("/política-de-privacidad")}
                        mode='outlined'
                        icon={'security'}
                        style={{marginRight: width > 500 ? 20 : 0, marginBottom:  width > 500 ? 0 : 10}}
                    >Revisa la política de privacidad</Button>
                    <Button
                        theme={theme}
                        onPress={() => linkTo("/")}
                        mode='contained'
                        icon={'download'}
                    >Descarga la aplicación</Button>
                </View>
            </Card>

        </View>

    )
}

export default IsComingSoonScreen