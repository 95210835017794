import { View, Dimensions } from 'react-native'
import React from 'react'
import { Avatar, Card, Text } from 'react-native-paper'
import { PreferencesContext } from '../context/PreferencesContext';
import { darkThemes, lightThemes } from '../theme/themeColors';
import Icon from '@expo/vector-icons/MaterialCommunityIcons';
import { useLinkTo } from '@react-navigation/native';

const screenWitdh = Dimensions.get('window').width;

const PrivacyPolicyScreen = () => {

    const { isThemeDark } = React.useContext(PreferencesContext);
    const theme = isThemeDark ? darkThemes : lightThemes;

    const linkTo = useLinkTo();

    return (

        <View style={{ justifyContent: 'center', alignItems: 'center', flex: 1, backgroundColor: theme.colors.background }}>
            <Card
                theme={theme}
                style={{ width: screenWitdh * 0.95, height: 'auto', alignSelf: 'center', marginTop: 20, marginBottom: 20}}
            >
                <Card.Title
                    title="Política de privacidad"
                    titleVariant='headlineMedium'
                    titleStyle={{ marginLeft: 10}}
                    subtitle="A Manyar"
                    style={{marginTop: 20}}
                    subtitleVariant='titleMedium'
                    subtitleStyle={{ marginLeft: 10}}
                    left={(props) => <Avatar.Icon {...props} icon="security" style={{ backgroundColor: theme.colors.inversePrimary, marginRight: 20 }} size={50}/>}
                    right={(props) => <Icon {...props} name="arrow-left-circle-outline" size={30} color={theme.colors.primary} style={{ marginRight: 20 }} onPress={() => linkTo("/web-en-desarrollo")}/>}
                />
                <View style={{ alignItems: 'flex-start' }}
                >
                    <Text variant='titleMedium' style={{ marginHorizontal: 20, marginTop: 20  }}>En A Manyar, valoramos y respetamos la privacidad de nuestros usuarios y nos tomamos muy en serio la protección de sus datos personales. A continuación, detallamos nuestra política de privacidad:</Text>
                    
                    <Text variant='titleMedium' style={{ marginHorizontal: 20, marginTop: 20  }}>1. Recopilación de información:</Text>

                    <Text variant='titleMedium' style={{ marginHorizontal: 20, marginTop: 20  }}>- Al registrarte en A Manyar, recopilamos información personal básica como tu nombre, dirección de correo electrónico, género y fecha de nacimiento.</Text>

                    <Text variant='titleMedium' style={{ marginHorizontal: 20, marginTop: 20  }}>- También recopilamos información sobre tus hábitos alimenticios, preferencias de ejercicio y objetivos de salud, para poder personalizar tus planes y recomendaciones.</Text>

                    <Text variant='titleMedium' style={{ marginHorizontal: 20, marginTop: 20  }}>- Además almacenamos la información de tus pagos realizados dentro de la aplicación para favorecer la experiencia de usuario en futuras actualizaciones.</Text>

                    <Text variant='titleMedium' style={{ marginHorizontal: 20, marginTop: 20  }}>- Leemos los datos de salud y actividad fisica de tu dipositivo previa solicitud de permisos para ayudarte a alcanzar tus metas.</Text>
                    
                    <Text variant='titleMedium' style={{ marginHorizontal: 20, marginTop: 20  }}>2. Uso de la información:</Text>

                    <Text variant='titleMedium' style={{ marginHorizontal: 20, marginTop: 20  }}>- Utilizamos la información que recopilamos para personalizar tus planes y recomendaciones, y para mejorar nuestros servicios.</Text>

                    <Text variant='titleMedium' style={{ marginHorizontal: 20, marginTop: 20  }}>- No compartimos la información personal de nuestros usuarios con terceros sin su consentimiento explícito, a menos que sea requerido por ley.</Text>

                    <Text variant='titleMedium' style={{ marginHorizontal: 20, marginTop: 20  }}>3. Seguridad:</Text>

                    <Text variant='titleMedium' style={{ marginHorizontal: 20, marginTop: 20  }}>- Tomamos medidas razonables para proteger la información personal de nuestros usuarios, incluyendo la encriptación de datossus contraeñas de usuario y el uso de tecnologías de seguridad.</Text>
                    
                    <Text variant='titleMedium' style={{ marginHorizontal: 20, marginTop: 20  }}>- Utilizamos bases de datos seguras en servicios en los servicios en la nube de Google Firebase.</Text>

                    <Text variant='titleMedium' style={{ marginHorizontal: 20, marginTop: 20  }}>4. Acceso y corrección de datos:</Text>

                    <Text variant='titleMedium' style={{ marginHorizontal: 20, marginTop: 20  }}>- Los usuarios pueden acceder y corregir su información personal en cualquier momento desde la configuración de la aplicación.</Text>

                    <Text variant='titleMedium' style={{ marginHorizontal: 20, marginTop: 20  }}>- Los usuarios pueden acceder y eliminar su cuenta y junto a ella toda su información personal en cualquier momento desde la configuración de la aplicación.</Text>

                    <Text variant='titleMedium' style={{ marginHorizontal: 20, marginTop: 20  }}>5. Cambios en la política de privacidad:</Text>

                    <Text variant='titleMedium' style={{ marginHorizontal: 20, marginTop: 20  }}>- Nos reservamos el derecho de modificar esta política de privacidad en cualquier momento, por lo que te recomendamos que la revises periódicamente.</Text>

                    <Text variant='titleMedium' style={{ marginHorizontal: 20, marginTop: 20, marginBottom: 20  }}>En resumen, en A Manyar nos comprometemos a proteger la privacidad de nuestros usuarios y a utilizar su información personal de manera responsable y segura. Si tienes alguna pregunta o inquietud acerca de nuestra política de privacidad, no dudes en contactarnos.</Text>
                </View>
            </Card>
        </View>
    )
}

export default PrivacyPolicyScreen