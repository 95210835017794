import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import IsComingSoonScreen from '../screens/IsComingSoonScreen';
import PrivacyPolicyScreen from '../screens/PrivacyPolicyScreen';


export type RootStakParams = {
  IsComingSoonScreen: undefined;
  PrivacyPolicyScreen: undefined;
}

const Stack = createStackNavigator<RootStakParams>();

export const HomeStackNavigator = () => {

  return (

        <Stack.Navigator screenOptions={{
            headerShown: false,
          }}>
            <Stack.Screen name="PrivacyPolicyScreen" component={PrivacyPolicyScreen} options={{ title: 'A Manyar | Política de privacidad' }}/>
            <Stack.Screen name="IsComingSoonScreen" component={IsComingSoonScreen} options={{ title: 'A Manyar | Web en desarrollo' }}/>
        </Stack.Navigator>
  );
}
