import { createContext } from 'react';

interface Preferences {
  toggleTheme: () => void;
  isThemeDark: boolean;
}

export const PreferencesContext = createContext<Preferences>({
  toggleTheme: () => {},
  isThemeDark: false,
});