import * as React from 'react';
import {
  Provider as PaperProvider,
  MD3LightTheme as DefaultTheme,
  MD3DarkTheme as SecondaryTheme,
  adaptNavigationTheme,
  Text
} from 'react-native-paper';
import {
  NavigationContainer,
  DarkTheme as NavigationDarkTheme,
  DefaultTheme as NavigationDefaultTheme
} from '@react-navigation/native';
import merge from 'deepmerge';
import { PreferencesContext } from './src/context/PreferencesContext';
import { darkThemes, lightThemes } from './src/theme/themeColors';
import { en, registerTranslation } from 'react-native-paper-dates'
import { HomeStackNavigator } from './src/navigator/HomeStackNavigator';
import * as Linking from 'expo-linking';
registerTranslation('en', en)


const prefix = Linking.createURL('/');

const lightTheme = {
  ...DefaultTheme,
  colors: lightThemes.colors,
};

const darkTheme = {
  ...SecondaryTheme,
  colors: darkThemes.colors,
};

const { LightTheme, DarkTheme } = adaptNavigationTheme({
  reactNavigationLight: NavigationDefaultTheme,
  reactNavigationDark: NavigationDarkTheme,
});

const CombinedDefaultTheme = merge(lightTheme, LightTheme);
const CombinedDarkTheme = merge(darkTheme, DarkTheme);
export default function App() {

  const config = {
    screens: {
      IsComingSoonScreen: 'web-en-desarrollo',
      PrivacyPolicyScreen: 'política-de-privacidad',
      Error404: '*'
    },
  };

  const linking = {
    prefixes: [prefix],
    config
  };

  const [isThemeDark, setIsThemeDark] = React.useState(false);

  let theme = isThemeDark ? CombinedDarkTheme : CombinedDefaultTheme;

  const toggleTheme = React.useCallback(() => {
    return setIsThemeDark(!isThemeDark);
  }, [isThemeDark]);

  const preferences = React.useMemo(
    () => ({
      toggleTheme,
      isThemeDark,
    }),
    [toggleTheme, isThemeDark]
  );

  return (

    <PreferencesContext.Provider value={preferences}>
      <PaperProvider theme={theme}>
        <NavigationContainer theme={theme} linking={linking} fallback={<Text>Loading...</Text>}>
            <HomeStackNavigator/>
        </NavigationContainer>
      </PaperProvider>
    </PreferencesContext.Provider>
    
  );

}
